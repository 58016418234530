<template>
  <router-link v-if="account" :to="{name: 'Account', params: {id: account.id}}">
    {{ account.name }}
  </router-link>
  <span v-else>{{ $t('common.misc.NA') }}</span>
</template>

<script>
export default {
  name: "AccountLink",

  props: {
    account: {type: Object, default: () => null}
  }
}
</script>

<style scoped>

</style>
