<template>
  <v-container fluid>
    <v-row no-gutters>
      <!-- Main Card -->
      <v-col cols="12" v-if="isLoaded" class="mt-6">
        <v-card class="mx-2">
          <v-row no-gutters>
            <v-col cols="12" class="mt-2">
              <v-row justify="space-between" no-gutters>
                <div class="mx-2">
                  <h1>{{ item.name }}</h1>
                </div>

                <div class="mx-2">
                  <v-chip label>{{ projectImpactType.text }}</v-chip>
                  <v-chip label>{{ item.state }}</v-chip>

                  <v-btn icon @click="setShowMainEditDialog(true)">
                    <v-icon>fas fa-pencil</v-icon>
                  </v-btn>

                  <v-menu bottom left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>fas fa-ellipsis-v</v-icon>
                      </v-btn>
                    </template>

                    <v-list dense>
                      <!-- Export MF -->
                      <v-list-item
                        :disabled="isLoading.exportMF"
                        @click="downloadMF"
                      >
                        <v-list-item-icon>
                          <v-icon small>fas fa-file-download</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Export MF</v-list-item-title>
                      </v-list-item>

                      <!-- Export Mail Meteor -->
                      <v-list-item
                        :disabled="isLoading.exportMailMeteor1"
                        @click="downloadExportMailMeteor1"
                      >
                        <v-list-item-icon>
                          <v-icon small>fas fa-file-download</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                          Export MailMeteor 1
                        </v-list-item-title>
                      </v-list-item>

                      <!-- Switch studies to state -->
                      <v-list-item
                        @click="toggleUpdateStudiesStatesDialog(true)"
                      >
                        <v-list-item-icon>
                          <v-icon small>fas fa-pencil</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                          {{
                            $t("projects-impact.updateStudiesStates.btnUpdate")
                          }}
                        </v-list-item-title>
                      </v-list-item>

                      <!-- Delete -->
                      <v-list-item @click="setShowDeleteDialog(true)">
                        <v-list-item-icon>
                          <v-icon small>far fa-trash</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                          {{ $t("common.buttons.delete") }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </v-row>
            </v-col>

            <!-- General info -->
            <v-col cols="12">
              <v-row no-gutters class="ma-2">
                <!-- FM Referent -->
                <v-col cols="12">
                  <span>{{ $t("projects-impact.labels.fm_referent") }} :</span>
                  <FMReferent :item="item.fm_referent" />
                </v-col>

                <!-- Account -->
                <v-col cols="12">
                  <span>{{ $t("projects-impact.labels.account") }} :</span>
                  <AccountLink :account="item.account" />
                </v-col>

                <!-- Known suppliers -->
                <v-col cols="12">
                  <span>
                    {{ $t("projects-impact.labels.knownSuppliers") }} :
                  </span>
                  <span>{{ item.knownSuppliers }}</span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <!-- Tabs card -->
      <v-col cols="12" class="mt-6">
        <v-card class="mx-2">
          <v-tabs class="mx-2" v-model="tab" grow @change="switchTab">
            <v-tab
              class="text-transform-none font-size-14"
              href="#tab-1"
              :exact="true"
            >
              {{ $t("projects-impact.tabs.milestones.title") }}
            </v-tab>
            <v-tab
              class="text-transform-none font-size-14"
              href="#tab-2"
              :exact="true"
            >
              {{ $t("projects-impact.tabs.studies.title") }}
            </v-tab>
            <v-tab
              class="text-transform-none font-size-14"
              href="#tab-3"
              :exact="true"
            >
              {{ $t("projects-impact.tabs.forms.title") }}
            </v-tab>
            <v-tab
              class="text-transform-none font-size-14"
              href="#tab-4"
              :exact="true"
            >
              {{ $t("projects-impact.tabs.links.title") }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab" class="ma-1">
            <!-- Milestones -->
            <v-tab-item value="tab-1">
              <ProjectImpactMilestonesTab v-if="item" :project-impact="item" />
              <Skeleton v-else type="card" :occurrence="1" />
            </v-tab-item>

            <!-- Studies -->
            <v-tab-item value="tab-2">
              <CommonStudiesTab v-if="item" :projectImpact="item" />
              <Skeleton v-else type="table" :occurrence="1" />
            </v-tab-item>

            <!-- Forms -->
            <v-tab-item value="tab-3">
              <MaterialFormsTab v-if="item" :projectImpact="item" />
              <Skeleton v-else type="table" :occurrence="1" />
            </v-tab-item>

            <!-- Links -->
            <v-tab-item value="tab-4">
              <ProjectImpactLinksTab v-if="item" :project_impact="item" />
              <Skeleton v-else type="table" :occurrence="1" />
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>

    <!-- Project impact main dialog -->
    <ProjectImpactMainDialog
      v-if="showDialog.main"
      :value="showDialog.main"
      :item="item"
      @close="(needRefresh) => setShowMainEditDialog(false, needRefresh)"
    />

    <!-- Project impact update studies states -->
    <ProjectsImpactUpdateStudiesStatesDialog
      v-if="showDialog.updateStudiesStates"
      :value="showDialog.updateStudiesStates"
      :project-impact="item"
      @close="toggleUpdateStudiesStatesDialog(false)"
    />

    <!-- Project impact delete dialog -->
    <DeleteDialog
      v-if="showDialog.delete"
      :value="showDialog.delete"
      :title="$t('projects-impact.deleteDialog.title')"
      @submit="deleteItem"
      @close="(needRefresh) => setShowDeleteDialog(false, needRefresh)"
    />
  </v-container>
</template>

<script>
import formatter from "@/mixins/formatter";
import tabsUtils from "@/mixins/tabs-utils";
export default {
  name: "ProjectImpactView",

  components: {
    ProjectsImpactUpdateStudiesStatesDialog: () =>
      import("@/components/ProjectsImpact/Actions/UpdateStudiesStates"),
    Skeleton: () => import("@/components/Common/Cards/Skeleton"),
    MaterialFormsTab: () =>
      import("@/components/Common/MaterialForms/MaterialFormsTab"),
    ProjectImpactLinksTab: () =>
      import("@/components/ProjectsImpact/Links/ProjectImpactLinksTab"),
    ProjectImpactMilestonesTab: () =>
      import(
        "@/components/ProjectsImpact/Milestones/ProjectImpactMilestonesTab"
      ),
    CommonStudiesTab: () =>
      import("@/components/Common/Studies/CommonStudiesTab"),
    FMReferent: () => import("@/components/Common/Cards/FMReferent"),
    AccountLink: () => import("@/components/Common/Accounts/AccountLink"),
    ProjectImpactMainDialog: () =>
      import("@/components/ProjectsImpact/ProjectImpactMainDialog"),
    DeleteDialog: () => import("@/components/Common/DeleteDialog"),
  },

  mixins: [formatter, tabsUtils],

  data() {
    return {
      showDialog: {
        main: false,
        delete: false,
        updateStudiesStates: false,
      },

      isLoaded: false,
      isLoading: {
        exportMF: false,
        btnSave: false,
        exportMailMeteor1: false,
      },
      item: null,
      tab: "tab-2",
    };
  },

  created() {
    this.getData();
  },

  methods: {
    getData() {
      this.isLoaded = false;
      this.isLoading.btnSave = false;

      this.$http
        .get(`/projects-impact/${this.$route.params.id}`, {
          headers: { Authorization: "Bearer " + this.$session.get("jwt") },
        })
        .then((res) => {
          this.item = res.data;
          this.$store.commit("pageHead/setTitle", `${this.item.name}`);
        })
        .catch((err) => {
          this.$store.commit("toast/showErrorAxios", err);
        })
        .finally(() => {
          this.isLoaded = true;
        });
    },

    deleteItem() {
      this.$http
        .delete(`/projects-impact/${this.item.id}`, {
          headers: { Authorization: "Bearer " + this.$session.get("jwt") },
        })
        .then((res) => {
          this.$store.commit(
            "toast/showSuccess",
            this.$t("projects-impact.deleteDialog.success")
          );
          this.setShowDeleteDialog(false);
          this.$router.push({ name: "ProjectsImpact" });
        })
        .catch((err) => {
          this.$store.commit("alert/showErrorAxios", err);
        })
        .finally(() => {
          this.isLoadingBtnSave = false;
        });
    },

    setShowMainEditDialog(val, reloadData = false) {
      if (reloadData) this.getData();

      this.showDialog.main = val;
    },

    setShowDeleteDialog(val, reloadData = false) {
      if (reloadData) this.getData();

      this.showDialog.delete = val;
    },

    downloadMF() {
      this.isLoading.exportMF = true;
      this.$http
        .get(`projects-impact/${this.item.id}/export-mf`, {
          responseType: "arraybuffer",
          headers: {
            Authorization: "Bearer " + this.$session.get("jwt"),
          },
        })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "export-mf.csv");
          //document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch((err) => {
          this.$store.commit("toast/showError", this.$t("common.errors.500"));
        })
        .finally(() => {
          this.isLoading.exportMF = false;
        });
    },

    downloadExportMailMeteor1() {
      this.isLoading.exportMailMeteor1 = true;
      this.$store.commit(
        "toast/showSuccess",
        this.$t("projects-impact.export.mailmeteor.waiting")
      );
      this.$http
        .get(`projects-impact/${this.item.id}/export-mailmeteor`, {
          responseType: "arraybuffer",
          headers: {
            Authorization: "Bearer " + this.$session.get("jwt"),
          },
        })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "export-mailmeteor.csv");
          //document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch((err) => {
          this.$store.commit("toast/showError", this.$t("common.errors.500"));
        })
        .finally(() => {
          this.isLoading.exportMailMeteor1 = false;
        });
    },
    toggleUpdateStudiesStatesDialog(val) {
      this.showDialog.updateStudiesStates = val;
    },
  },

  computed: {
    projectImpactType() {
      return this.$t("constants.projectsImpactTypes.list").find(
        (el) => el.value === this.item.type
      );
    },
  },
};
</script>

<style scoped></style>
