<template>
  <v-container fluid>
    <v-row justify="start" class="ma-2" no-gutters>
      <v-col cols="12">
        <h1>{{ $t("projects-impact.title") }}</h1>
      </v-col>

      <!-- Buttons -->
      <v-col cols="12" class="mt-6 text-right">
        <v-btn color="primary" @click="setShowAddDialog(true)">
          {{ $t("projects-impact.buttons.add") }}
        </v-btn>
      </v-col>

      <v-col cols="12" class="mt-6">
        <div>
          <v-text-field
            v-model="search"
            append-icon="fas fa-search"
            :label="$t('common.inputs.search')"
            clearable
            v-on:click:clear="clearInput"
            @keyup.enter.native="searchTrigger"
            outlined
          ></v-text-field>
        </div>
      </v-col>

      <!-- Table -->
      <v-col cols="12" class="mt-2">
        <v-data-table
          :headers="headers"
          :items="items"
          :server-items-length="totalItems"
          :options.sync="pagination"
          :loading="loading"
          :items-per-page="20"
          style="min-height: 600px"
          sort-by="name"
          :sort-desc="false"
          :footer-props="{ 'items-per-page-options': itemsPerPage }"
          class="elevation-1"
        >
          <!-- Name -->
          <template v-slot:item.name="{ item }">
            <router-link
              :to="{ name: 'ProjectImpact', params: { id: item.id } }"
            >
              {{ item.name }}
            </router-link>
          </template>

          <!-- FM referent -->
          <template v-slot:item.fm_referent="{ item }">
            <FMReferent :item="item.fm_referent" />
          </template>

          <!-- Account -->
          <template v-slot:item.account="{ item }">
            <AccountLink :account="item.account" />
          </template>

          <!-- Nb products -->
          <template v-slot:item.nb_product="{ item }"></template>

          <!-- Type -->
          <template v-slot:item.type="{ item }">
            <v-chip label>{{ item.type }}</v-chip>
          </template>

          <!-- State -->
          <template v-slot:item.state="{ item }">
            <v-chip label>{{ item.state }}</v-chip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- Main project dialog -->
    <ProjectImpactMainDialog
      v-if="showDialog.add"
      :value="showDialog.add"
      :item="null"
      @close="(needRefresh) => setShowAddDialog(false, needRefresh)"
    />
  </v-container>
</template>

<script>
import vuetifyDatatable from "@/mixins/vuetifyDataTable";
import formatter from "@/mixins/formatter";
import AccountLink from "../../components/Common/Accounts/AccountLink";

export default {
  name: "ProjectsImpactView",

  components: {
    AccountLink,
    FMReferent: () => import("@/components/Common/Cards/FMReferent"),
    ProjectImpactMainDialog: () =>
      import("@/components/ProjectsImpact/ProjectImpactMainDialog"),
  },

  mixins: [vuetifyDatatable, formatter],

  data() {
    return {
      headers: [
        {
          text: this.$t("projects-impact.datatable.headers.name"),
          value: "name",
          sortable: false,
          width: "20%",
        },
        {
          text: this.$t("projects-impact.datatable.headers.fm_referent"),
          value: "fm_referent",
          sortable: false,
          width: "20%",
        },
        {
          text: this.$t("projects-impact.datatable.headers.account"),
          value: "account",
          sortable: false,
          width: "20%",
        },
        {
          text: this.$t("projects-impact.datatable.headers.nb_products"),
          value: "nb_products",
          sortable: false,
          width: "10%",
        },
        {
          text: this.$t("projects-impact.datatable.headers.type"),
          value: "type",
          sortable: false,
          width: "10%",
        },
        {
          text: this.$t("projects-impact.datatable.headers.state"),
          value: "state",
          sortable: false,
          width: "10%",
        },
      ],
      URL_API: {
        GET_ALL: "/projects-impact",
      },

      showDialog: {
        add: false,
      },
      isLoadingExportBtn: false,
      savePaginationURL: true,
    };
  },

  created() {
    this.$store.commit(
      "pageHead/setTitle",
      this.$t("projects-impact.pageTitle")
    );
  },

  methods: {
    setShowAddDialog(val, reloadData = false) {
      if (reloadData) this.searchTrigger();
      this.showDialog.add = val;
    },

    goToOne(obj) {
      this.$router.push({
        name: "ProjectImpact",
        params: { id: obj.id },
      });
    },
  },
};
</script>

<style scoped></style>
